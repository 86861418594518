<template>
  <el-select
    v-model="locationsModel"
    @change="locationsChanged"
    autocomplete="new-password"
    :placeholder="$t('COMMON.LOCATIONS')"
    :filterable="true"
    :multiple="true"
    :disabled="disabled"
    remote
    :remote-method="getLocations"
    :loading="loading"
  >
    <el-option
      v-for="location in locationsList"
      :key="location.id"
      :value="location.id"
      :label="`${location.name}`"
    >
    </el-option>
  </el-select>
</template>

<script>
import { difference } from "lodash";
import { Option, Select } from "element-ui";

export default {
  name: "locations-selector",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
      description: "Disable the input",
    },
    locations: {
      type: Array,
      default: () => [],
      description: "Locations",
    },
    organization: {
      type: String,
      default: null,
      description: "Organization id",
    },
  },

  data() {
    return {
      locationsModel: [...this.locations].map((item) => item.id),
      locationsList: {},
    };
  },

  setup() {},

  created() {
    this.getLocations(null, this.locations);
  },

  methods: {
    async getLocations(query = null, locations = null) {
      try {
        this.loading = true;
        let params = {
          sort: "name",
          filter: {
            ...(query ? { search: query } : {}),
            ...(locations
              ? { with_ids: locations.map((item) => item.id) }
              : {}),
          },
          page: {
            number: 1,
            size: 10,
          },
        };

        if (this.organization) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              organization: this.organization,
            },
          };
        }

        await this.$store.dispatch("locations/list", params);
        const locationsArr = await this.$store.getters["locations/list"];
        this.locationsList = {};
        locationsArr.forEach((location) => {
          this.locationsList[location.id] = location;
        });
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },

    async locationsChanged(locations) {
      this.$emit(
        "locationsChanged",
        [...locations].map((item) => ({ type: "locations", id: item }))
      );
    },
  },

  watch: {
    locations(locations) {
      if (locations && locations.length != 0) {
        const locationsData = locations.map((item) => item.id);
        if (difference(locationsData, this.locationsModel).length !== 0) {
          this.locationsModel = locationsData;
          this.getLocations(null, locations);
        }
      } else {
        this.locationsModel = [];
      }
    },
    organization(organization) {
      this.getLocations();
    },
  },
};
</script>
